import { HttpService } from 'http/httpService'
import { Result, success, failure } from 'result'
import { Recurrence, PricingType } from 'products/articleConfigurationService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { GlobalAccountSettingsFormData } from '../../globalPricingSettings/studio/types'

export type PriceVariation = 'low' | 'medium' | 'high' | 'custom'

export interface PricingData {
    productId: string
    account: string
    originalPrice: string
    minAcceptedPrice: string
    avgTargetPrice: string
    gatePrice: string
    lastModifiedAt: string
    lastModifiedBy: string
    overrides?: any
    version: string | null
    stepSize: string | null
    charmPricing: boolean | null
    priceVariation: PriceVariation
    renewAutomatically?: boolean | null
    renewIntoMonthly?: boolean | null
}

type SendPricingSettingsBody = {
    pricingType: string
    id: string
    account: string
    originalPrice: number | null
    minAcceptedPrice: number | null
    avgTargetPrice: number | null
    gatePrice: number | null | undefined
    stepSize: string | null
    charmPricing: boolean | null
    version: string | null | undefined
    priceVariation?: PriceVariation | null
}

interface PriceSettings {
    productId: string
}

export type AdjustmentType = 'ABSOLUTE' | 'PERCENTAGE' | 'SET'
export type PriceSetting = 'BOX' | 'AVG' | 'MIN'
export type PricingSettingToEnforce = 'minimum' | 'average' | 'maximum'
export interface CreateExceptionServerError {
    type: 'server_error'
    statusCode: number
}

export interface CreateExceptionValidationError {
    type: 'conflict' | 'validation' | 'unknown'
    overrideName?: string
    overrideId?: string
    productId: string
    message: string
}

export type CreateExceptionError = CreateExceptionServerError | CreateExceptionValidationError

export interface CreatePricingSettingsServerError {
    type: 'server_error'
    statusCode: number
}

export interface CreatePricingSettingsValidationError {
    type: 'override_validation' | 'settings_validation' | 'unknown_validation_error'
    overrideName?: string
    overrideId?: string
    productId?: string
    message: string
}
export type CreatePricingSettingsError = CreatePricingSettingsServerError | CreatePricingSettingsValidationError

export interface ExceptionResponse {
    id: string
    name: string
    originalPriceAdjustmentType: AdjustmentType
    originalPriceAdjustment: string | null
    minAcceptedPriceAdjustmentType: AdjustmentType
    minAcceptedPriceAdjustment: string | null
    targetAvgPriceAdjustmentType: AdjustmentType
    targetAvgPriceAdjustment: string | null
    startDate: string
    endDate: string
    weekdays: string
    startTime: string
    endTime: string
    priceSettings: PriceSettings[]
    productNames: string
    recurrence: Recurrence
    active: boolean
    pricingType: PricingType | null
    enforceFrom: PricingSettingToEnforce | null
    intervals: DynamicInterval[] | null
    overrideType: OverrideType | null
    forResellers: string[]
    resellersNames: string
}

export interface Exception {
    id: string
    name: string
    originalPriceAdjustmentType: AdjustmentType
    originalPriceAdjustment: number | null
    minAcceptedPriceAdjustmentType: AdjustmentType
    minAcceptedPriceAdjustment: number | null
    targetAvgPriceAdjustmentType: AdjustmentType
    targetAvgPriceAdjustment: number | null
    startDate: string
    endDate: string
    weekdays: string
    startTime: string
    endTime: string
    priceSettings: PriceSettings[]
    productNames: string
    recurrence: Recurrence
    active: boolean
    pricingType: PricingType | null
    enforceFrom: PricingSettingToEnforce | null
    intervals: DynamicInterval[] | null
    overrideType: OverrideType | null
    version: string | null
    forResellers: string[]
}

interface ExceptionData {
    id?: string | null
    name: string
    account: string
    priceSettings: string[]
    pricingType: PricingType | null
    minAcceptedPriceAdjustment: string | null
    minAcceptedPriceAdjustmentType: AdjustmentType | null
    originalPriceAdjustment: string | null
    originalPriceAdjustmentType?: AdjustmentType | null
    targetAvgPriceAdjustment: string | null
    targetAvgPriceAdjustmentType: AdjustmentType | null
    weekdays: string | null
    recurrence: Recurrence | null
    startDate: string | null
    endDate: string | null
    startTime: string | null
    endTime: string | null
    enforceFrom: PricingSettingToEnforce | null
    intervals: DynamicInterval[] | null
    overrideType: OverrideType | null
    version: string | null
    forResellers: string[]
}

export interface ExceptionForOverview {
    name: string
    id: string | null
    account: string
    overrideType: OverrideType | null
    priceSettings: string[]
    pricingType: PricingType | null
    minAcceptedPriceAdjustment: string | null
    minAcceptedPriceAdjustmentType: AdjustmentType | null
    originalPriceAdjustment: string | null
    originalPriceAdjustmentType?: AdjustmentType | null
    targetAvgPriceAdjustment: string | null
    targetAvgPriceAdjustmentType: AdjustmentType | null
    intervals: DynamicInterval[]
}

export interface DetailedException extends ExceptionResponse {
    account: string
    active: boolean
    version: string | null
}

export interface DynamicInterval {
    startDay: number | null
    endDay: number | null
    adjustment: string | null
    adjustmentType: 'ABSOLUTE' | 'PERCENTAGE'
}

export type OverrideType = 'adjust' | 'enforce' | 'dynamic'

export type OverviewData = OverviewAdjustmentData | OverviewIntervalData

export interface DynamicOverviewInterval {
    startDay: number
    endDay: number
    previous: number
    new: number
    error: string | null
}

export interface OverviewAdjustmentData {
    __typename: 'RegularPriceChange'
    productId: string
    price: {
        previous: number
        new: number
        error: string | null
    } | null
    targetAvgPrice: {
        previous: number
        new: number
        error: string | null
    } | null
    minPrice: {
        previous: number
        new: number
        error: string | null
    } | null
}

export interface OverviewIntervalData {
    __typename: 'DynamicPriceChange'
    productId: string
    priceIntervals: DynamicOverviewInterval[]
}

interface OverrideInSaveResponse {
    id: string
}
interface SaveOverrideResponse {
    ok: boolean
    override: OverrideInSaveResponse
}

interface PriceSettingsInSaveResponse {
    originalPrice: number
    minAcceptedPrice: number
    avgTargetPrice: number
}

interface SavePricingSettingsResponse {
    savePriceSettings: {
        ok: boolean
        priceSettings: PriceSettingsInSaveResponse
    }
}

interface CalendarProduct {
    id: string
    number_of_tickets: number
    price: string
    original_price: string
}
export interface CalendarPrice {
    dt: string
    startTime: string | null
    endTime: string | null
    color: number
    products: CalendarProduct[]
    expires_at: number
    signature: string
}

export type GlobalPricingGoal =
    | 'opt_revenue'
    | 'opt_max_sold'
    | 'opt_visitor_dist'
    | 'opt_max_sold_by_availability'
    | 'opt_rule_based'
    | 'early_bird_by_inventory'
    | 'early_bird_by_time'

export type DaySegment = 'morning' | 'afternoon' | 'evening' | 'night'

export interface GlobalAccountSettingsWithoutIndustry {
    audienceLocation: number
    avgDaysTicketsBoughtInAdvance: number
    boxOfficePriceOnVisitDate: boolean
    chargeLateBookings: boolean
    daytimeImportance: DaySegment[][]
    earlyBookingsImpact: number
    holidayImportance: number
    lateBookingsImpact: number
    monthImportance: number[]
    pricingGoal: GlobalPricingGoal
    rainImpact: number
    rewardEarlyBookings: boolean
    settingsWeight: number
    temperatureImpact: number
    venueTypeOutdoor: number
    weekdayImportance: number[]
    features: {
        dynamicModelConfiguration: {
            highSales: number
            usualSales: number
            lowSales: number
            inventoryStep: number
            timeStep: number
            startBefore: number
            forecastWeight: number
        }
    }
}

export interface GlobalAccountSettings extends GlobalAccountSettingsWithoutIndustry {
    industry: string
}

export interface GlobalAccountSettingsResponse {
    settings: GlobalAccountSettings
}

export interface BulkPricingSettingsResponse {}

export interface BulkPricingSettingsError {
    type:
        | 'server_error'
        | 'original_price_invalid_for_existing_settings'
        | 'avg_target_price_invalid_for_existing_settings'
        | 'min_accepted_price_above_existing_settings'
        | 'gate_price_below_existing_settings'
    message: string
    productIds?: string[]
}

interface ProductDataForBulk {
    productId: string
    version: string
}

export class PricingService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private pricingEndpoint: string,
    ) {}

    async getProductPricing(ids: string[]): Promise<PricingData[]> {
        const query = `query PriceSettings($productIds: [ID]) {
      priceSettings(productIds: $productIds) {
        productId
        account
        originalPrice
        startingPrice
        minAcceptedPrice
        avgTargetPrice
        gatePrice
        lastModifiedAt
        lastModifiedBy
        version
        stepSize
        charmPricing
        priceVariation
        overrides {
          id
          account
          name
          startDate
          endDate
          weekdays
          startTime
          endTime
          recurrence
          originalPriceAdjustment
          originalPriceAdjustmentType
          targetAvgPriceAdjustment
          targetAvgPriceAdjustmentType
          minAcceptedPriceAdjustment
          minAcceptedPriceAdjustmentType
          pricingType
        }
      }
    }`

        const requestBody = JSON.stringify({
            query: query,
            variables: { productIds: ids },
        })

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            const reason = await response.text()
            throw new Error(`Oops! Something went wrong, please try again: ${reason}`)
        }

        const body = await response.json()
        return body.data.priceSettings
    }

    async sendPricingSettings({
        pricingType,
        id,
        account,
        originalPrice,
        minAcceptedPrice,
        avgTargetPrice,
        gatePrice,
        priceVariation,
        stepSize,
        charmPricing,
        version,
    }: SendPricingSettingsBody): Promise<Result<SavePricingSettingsResponse, CreatePricingSettingsError>> {
        const mutation = `mutation PriceSettings($productId: String!, $account: String!, $originalPrice: String!, $pricingType: String,
                            $minAcceptedPrice: String, $avgTargetPrice: String, $gatePrice: String, $version: String
                            $stepSize: String, $charmPricing: Boolean, $priceVariation: String) {
      savePriceSettings(productId: $productId, account: $account, originalPrice: $originalPrice, stepSize: $stepSize,
      	                 minAcceptedPrice: $minAcceptedPrice, avgTargetPrice: $avgTargetPrice, gatePrice: $gatePrice,
                         version: $version, pricingType: $pricingType, charmPricing: $charmPricing, priceVariation: $priceVariation) {
        ok
      	priceSettings {
          originalPrice,
          minAcceptedPrice,
          avgTargetPrice,
          gatePrice,
          stepSize,
          charmPricing
          priceVariation
        }
      }
    }`

        const requestBody = JSON.stringify({
            query: mutation,
            variables: {
                pricingType,
                productId: id,
                account: account,
                originalPrice: typeof originalPrice === 'number' ? String(originalPrice) : null,
                minAcceptedPrice: typeof minAcceptedPrice === 'number' ? String(minAcceptedPrice) : null,
                avgTargetPrice: typeof avgTargetPrice === 'number' ? String(avgTargetPrice) : null,
                gatePrice: typeof gatePrice === 'number' ? String(gatePrice) : null,
                version: version || null,
                stepSize: stepSize,
                charmPricing: charmPricing,
                priceVariation,
            },
        })

        const logEventType: EventType = 'pricing_settings_updated'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: { pricingType, id, originalPrice, minAcceptedPrice, avgTargetPrice, gatePrice, version },
        }

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'server_error',
                statusCode: response.status,
            } as CreatePricingSettingsError)
        }

        const body = await response.json()
        if (body.errors) {
            this.loggingService.logError(body, logEventType, logEventData)
            const validationError = body.errors.find((e: any) => {
                try {
                    const parsed = JSON.parse(e.message)
                    return (
                        parsed['code'] === 'price_settings_validation' ||
                        'price_settings_override_validation' ||
                        'bad_override_enforce_source'
                    )
                } catch (err) {
                    return failure({
                        type: 'server_error',
                        statusCode: response.status,
                    } as CreatePricingSettingsError)
                }
            })
            if (validationError) {
                try {
                    const errorObj = JSON.parse(validationError['message'])
                    const errorType = errorObj['code']
                    if (errorType === 'price_settings_validation') {
                        return failure({
                            type: 'settings_validation',
                            productId: errorObj.productId,
                            message: errorObj.message,
                        } as CreatePricingSettingsError)
                    }
                    if (errorType === 'price_settings_override_validation') {
                        return failure({
                            type: 'override_validation',
                            overrideName: errorObj.overrideName,
                            overrideId: errorObj.overrideId,
                            message: errorObj.message,
                        } as CreatePricingSettingsError)
                    }
                    if (errorType === 'bad_override_enforce_source') {
                        return failure({
                            type: 'override_validation',
                            overrideName: errorObj.overrideName,
                            overrideId: errorObj.overrideId,
                            message: 'Value enforced by exception is not set in pricing settings',
                        } as CreatePricingSettingsError)
                    }
                } catch {
                    return failure({
                        type: 'unknown_validation_error',
                        message: validationError['message'],
                    } as CreatePricingSettingsError)
                }
            }
        }

        this.loggingService.logAction(logEventType, logEventData)
        return success(body.data)
    }

    async sendBulkPricingSettings(
        products: ProductDataForBulk[],
        account: string,
        originalPrice: number | null,
        minAcceptedPrice: number | null,
        avgTargetPrice: number | null,
        gatePrice: number | null,
        pricingType: string | null,
    ): Promise<Result<BulkPricingSettingsResponse, BulkPricingSettingsError>> {
        const mutation = `mutation BulkPriceSettings($products: [ProductsWithVersions!], $account: String!, $originalPrice: String,
                            $minAcceptedPrice: String, $avgTargetPrice: String, $gatePrice: String,
                            $pricingType: String) {
      saveBulkPriceSettings(products: $products, account: $account, originalPrice: $originalPrice,
                         minAcceptedPrice: $minAcceptedPrice, avgTargetPrice: $avgTargetPrice, gatePrice: $gatePrice,
                         pricingType: $pricingType) {
        ok
      }
    }`
        const requestBody = JSON.stringify({
            query: mutation,
            variables: {
                products: products,
                account: account,
                originalPrice: originalPrice,
                minAcceptedPrice: minAcceptedPrice,
                avgTargetPrice: avgTargetPrice,
                gatePrice: gatePrice,
                pricingType: pricingType,
            },
        })

        const logEventType: EventType = 'pricing_settings_updated'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: { products, originalPrice, minAcceptedPrice, avgTargetPrice, gatePrice },
        }

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            const reason = await response.text()
            return failure({
                type: 'server_error',
                message: `Oops! Something went wrong, please try again: ${reason}`,
            } as BulkPricingSettingsError)
        }

        const body = await response.json()
        this.loggingService.logError(body, logEventType, logEventData)
        if (body.errors) {
            try {
                const errorInfo = JSON.parse(body.errors[0].message)
                return failure({
                    type: errorInfo.code,
                    productIds: errorInfo.productIds,
                    message: errorInfo.message,
                } as BulkPricingSettingsError)
            } catch {
                return failure({
                    type: 'server_error',
                    message: 'Backend returned an unknown error.',
                } as BulkPricingSettingsError)
            }
        }

        this.loggingService.logAction(logEventType, logEventData)
        return success(body.data)
    }

    async getExceptionsList(account: string, sortBy: string, sortDirection: string): Promise<ExceptionResponse[]> {
        const overridesQuery = `
        query Overrides($account: String, $sortBy: OverrideSort, $sortDirection: SortDirection) {
          overrides(account: $account, sortBy: $sortBy, sortDirection: $sortDirection) {
            id,
            name,
            forResellers,
            startDate,
            endDate,
            weekdays,
            startTime,
            endTime,
            originalPriceAdjustment,
            originalPriceAdjustmentType,
            targetAvgPriceAdjustment,
            targetAvgPriceAdjustmentType,
            minAcceptedPriceAdjustment,
            minAcceptedPriceAdjustmentType,
            recurrence,
            active,
            pricingType,
            enforceFrom,
            intervals {
              startDay,
              endDay,
              adjustment,
              adjustmentType
            },
            priceSettings {
              productId
            },
            overrideType
          }
        }
      `
        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: overridesQuery,
                variables: { account, sortBy, sortDirection },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.overrides
    }

    async getException(id: string): Promise<DetailedException> {
        const exceptionQuery = `
        query Override($id: ID!) {
          override(id: $id) {
            name,
            account,
            id,
            forResellers,
            active,
            originalPriceAdjustment,
            startDate,
            endDate,
            weekdays,
            startTime,
            endTime,
            originalPriceAdjustmentType,
            targetAvgPriceAdjustment,
            targetAvgPriceAdjustmentType,
            minAcceptedPriceAdjustment,
            minAcceptedPriceAdjustmentType,
            recurrence,
            pricingType,
            enforceFrom,
            version
            priceSettings {
              productId
            },
            overrideType,
            intervals{
              startDay,
              endDay,
              adjustment,
              adjustmentType
            }
          }
        }
      `

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: exceptionQuery,
                variables: { id },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.data.override
    }

    async createException(exceptionData: ExceptionData): Promise<Result<SaveOverrideResponse, CreateExceptionError>> {
        const mutation = `
    mutation Override($id: ID, $name: String!, $account: String!,
      $minAcceptedPriceAdjustment: String, $minAcceptedPriceAdjustmentType: String,
      $originalPriceAdjustment: String, $originalPriceAdjustmentType: String,
      $targetAvgPriceAdjustment: String, $targetAvgPriceAdjustmentType: String,
      $recurrence: String, $forResellers: [String],
      $startDate: Date, $startTime: Time, $endDate: Date, $endTime: Time,
      $weekdays: String, $priceSettings: [String], $pricingType: String, $enforceFrom: String,
      $intervals: [DynamicIntervalInput], $overrideType: String, $version: String) {
      saveOverride(id: $id, name: $name, account: $account, endDate: $endDate, endTime: $endTime,
        minAcceptedPriceAdjustment: $minAcceptedPriceAdjustment,
        minAcceptedPriceAdjustmentType: $minAcceptedPriceAdjustmentType,
        originalPriceAdjustment: $originalPriceAdjustment,
        originalPriceAdjustmentType: $originalPriceAdjustmentType,
        targetAvgPriceAdjustment: $targetAvgPriceAdjustment,
        targetAvgPriceAdjustmentType: $targetAvgPriceAdjustmentType,
        recurrence: $recurrence, startDate: $startDate, startTime: $startTime,
        weekdays: $weekdays, priceSettings: $priceSettings, pricingType: $pricingType,
        enforceFrom: $enforceFrom, intervals: $intervals, overrideType: $overrideType,
        version: $version, forResellers: $forResellers) {
        ok
        override {
          id
        }
      }
    }`
        const {
            id,
            name,
            account,
            priceSettings,
            recurrence,
            weekdays,
            minAcceptedPriceAdjustment,
            minAcceptedPriceAdjustmentType,
            originalPriceAdjustment,
            originalPriceAdjustmentType,
            targetAvgPriceAdjustment,
            targetAvgPriceAdjustmentType,
            startTime,
            startDate,
            endTime,
            endDate,
            pricingType,
            enforceFrom,
            intervals,
            overrideType,
            version,
            forResellers,
        } = exceptionData

        const logEventType: EventType = id ? 'pricing_exception_updated' : 'pricing_exception_created'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: exceptionData,
        }

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: mutation,
                variables: {
                    id,
                    name,
                    account,
                    priceSettings,
                    recurrence,
                    weekdays,
                    minAcceptedPriceAdjustment,
                    minAcceptedPriceAdjustmentType,
                    originalPriceAdjustment,
                    originalPriceAdjustmentType,
                    targetAvgPriceAdjustment,
                    targetAvgPriceAdjustmentType,
                    startTime,
                    startDate,
                    endTime,
                    endDate,
                    pricingType,
                    enforceFrom,
                    intervals,
                    overrideType,
                    version,
                    forResellers,
                },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            return failure({
                type: 'server_error',
                statusCode: response.status,
            } as CreateExceptionError)
        }
        const body = await response.json()
        if (body.errors) {
            this.loggingService.logError(body, logEventType, logEventData)
            const conflictOrValidationError = body.errors.find((e: any) => {
                try {
                    const parsed = JSON.parse(e.message)
                    return (
                        parsed['code'] === 'override_conflict' ||
                        parsed['code'] === 'override_validation' ||
                        parsed['code'] === 'bad_override_enforce_source'
                    )
                } catch (err) {
                    return failure({
                        type: 'server_error',
                        statusCode: response.status,
                    } as CreateExceptionError)
                }
            })
            if (conflictOrValidationError) {
                try {
                    const errorObj = JSON.parse(conflictOrValidationError['message'])
                    const errorType = errorObj['code']
                    if (errorType === 'override_conflict') {
                        return failure({
                            type: 'conflict',
                            overrideName: errorObj.overrideName,
                            overrideId: errorObj.overrideId,
                            productId: errorObj.productId,
                        } as CreateExceptionError)
                    } else if (errorType === 'override_validation') {
                        return failure({
                            type: 'validation',
                            productId: errorObj.productId,
                            message: errorObj.message,
                        } as CreateExceptionError)
                    } else if (errorType === 'bad_override_enforce_source') {
                        return failure({
                            type: 'validation',
                            productId: errorObj.productId,
                            message: `Cannot save exception: enforce source is not set on product ${errorObj.productId}`,
                        } as CreateExceptionError)
                    } else {
                        return failure({
                            type: 'unknown',
                            message: 'Unknown error',
                        } as CreateExceptionError)
                    }
                } catch {
                    return failure({
                        type: 'unknown',
                        message: conflictOrValidationError['message'],
                    } as CreateExceptionError)
                }
            }
        }

        const exceptionId = id ? id : body.data.saveOverride.override.id
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: exceptionId },
        })
        return success(body.data.saveOverride)
    }

    async deleteException(id: string): Promise<boolean> {
        const query = `mutation DeleteOverride($id: ID!) {
      deleteOverride(id: $id) {
        ok
        errorCode
      }
    }`

        const logEventType: EventType = 'pricing_exception_deleted'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: { id },
        }

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: query,
                variables: { id },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        if (!body.data.deleteOverride.ok) {
            this.loggingService.logError(body, logEventType, logEventData)
            throw new Error(`Cannot delete pricing expection ${id}`)
        }

        this.loggingService.logAction(logEventType, logEventData)
        return body.data
    }

    async getExceptionOverview(override: ExceptionForOverview): Promise<OverviewData[]> {
        const query = `query Overview($override: OverrideInput!) {
      overrideOverview(override: $override) {
        __typename
        ... on RegularPriceChange {
          productId
          price {
            previous
            new
            error
          }
          targetAvgPrice {
            previous
            new
            error
          }
          minPrice {
            previous
            new
            error
          }
        }
        ... on DynamicPriceChange {
          productId
          priceIntervals {
            previous
            new
            startDay
            endDay
            error
          }
        }
      }
    }
  `

        const requestBody = JSON.stringify({
            query: query,
            variables: { override },
        })

        const response = await this.httpService.fetch(`${this.pricingEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            const reason = await response.text()
            throw new Error(`Oops! Something went wrong, please try again: ${reason}`)
        }

        const body = await response.json()
        return body.data.overrideOverview
    }

    async saveGlobalAccountSettings(account: string, settings: GlobalAccountSettingsFormData): Promise<boolean> {
        const logEventType: EventType = 'global_pricing_settings_saved'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: {
                settings,
                account,
            },
        }

        const response = await this.httpService.fetch(
            `${this.pricingEndpoint}settings/api/v1/account_settings/${account}/`,
            {
                method: 'POST',
                body: JSON.stringify(settings),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)

        return true
    }

    async getGlobalAccountSettings(account: string): Promise<GlobalAccountSettingsResponse | null> {
        const response = await this.httpService.fetch(
            `${this.pricingEndpoint}settings/api/v1/account_settings/${account}/`,
        )
        const logEventType: EventType = 'global_pricing_settings_fetched'
        const logEventData: ActionEventData = {
            category: 'global_pricing_settings',
            payload: { account },
        }

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Pricing setting service returned status code: ${response.status}`)
        }
        const body = await response.json()
        if (body.errors) {
            this.loggingService.logError(body, logEventType, logEventData)
            throw new Error('Could not save account settings')
        }

        const accountSettings = body
        this.loggingService.logAction(logEventType, { ...logEventData, accountSettings })
        return { settings: accountSettings }
    }

    async getDefaultIndustrySettings(
        model: string = 'opt_revenue',
        version: string = 'V2',
    ): Promise<GlobalAccountSettings | null> {
        const response = await this.httpService.fetch(
            `${this.pricingEndpoint}api/global-pricing-settings/?model=${model}&version=${version}`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            const logEventType: EventType = 'default_global_pricing_settings_fetched'

            const logEventData: ActionEventData = {
                category: 'global_pricing_settings',
                payload: { model, version },
            }

            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Pricing settings service returned status code: ${response.status}`)
        }
        return await response.json()
    }
}
