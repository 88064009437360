import { AuthenticatedHttpService } from 'http/httpService'

export interface PaymentPlan {
    paymentInterval: 'P1M' | 'P1Y'
    totalPrice: number
    automaticRenewal?: 'P1M' | 'P1Y'
    initialPayment?: number
    billingGracePeriod?: string
    minimumCommitmentDuration?: string
}

export class PaymentPlansService {
    constructor(private httpService: AuthenticatedHttpService, private backofficeEndpoint: string) {}

    private getPaymentPlansUrl(accountSlug: string, articleUuid: string): string {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/articles/${articleUuid}/payment_plans/`
    }

    async getPaymentPlans(accountSlug: string, articleUuid: string): Promise<PaymentPlan[]> {
        const response = await this.httpService.fetch(this.getPaymentPlansUrl(accountSlug, articleUuid), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        })

        return response.json()
    }

    async createPaymentPlans(
        accountSlug: string,
        articleUuid: string,
        paymentPlans: PaymentPlan[],
    ): Promise<PaymentPlan[]> {
        const response = await this.httpService.fetch(this.getPaymentPlansUrl(accountSlug, articleUuid), {
            method: 'POST',
            body: JSON.stringify(paymentPlans),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error('Failed to create payment plans')
        }

        return response.json()
    }
}
