import cleanDeep from 'clean-deep'
import { generatePath } from 'react-router-dom'

export const pathUtils = {
    addQueryString: (originalPath: string, query: string = ''): string => {
        if (query && query[0] !== '?') {
            return originalPath + '?' + query
        }

        return originalPath + query
    },
    addPath: (originalPath: string, pathToAdd: string, query?: string) => {
        let str = originalPath

        if (str && str[str.length - 1] !== '/' && pathToAdd && pathToAdd[0] !== '/') {
            str = str + '/' + pathToAdd
        } else {
            str = str + pathToAdd
        }

        return pathUtils.addQueryString(str, query)
    },
    removePath: (originalPath: string, pathToRemove: string | RegExp, query?: string) => {
        const path = originalPath.replace(pathToRemove, '')

        return pathUtils.addQueryString(path, query)
    },
    populateParams: (path: string, params: any) => {
        return generatePath(path, params)
    },
    formatSearchParams: (data: Record<string, string | number | undefined>) => {
        if (!data || typeof data !== 'object') {
            return data
        }

        const removeEmptyData = cleanDeep(data) as Record<string, string>
        const url = new URLSearchParams(removeEmptyData)

        return url.toString()
    },
}
